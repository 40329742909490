import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import Logo from "../../../assets/logo_mathasa_croped.png";
import { CircleLoader } from "../components/loader_ui";

class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      data: {
        login_data: false,
        password: false,
      },
      loading: false,
    };
  }

  inputHandler = (input, type) => {
    const data = { ...this.state.data };
    data[type] = input.target.value;
    var code = input.keyCode || input.which;

    this.setState({ data }, () => {
      if (code === 13) {
        this.loginHandler();
      }
    });
  };

  loginHandler = () => {
    this.setState({ loading: true });
    this.props.onAuth(this.state.data);
  };

  UNSAFE_componentWillReceiveProps() {
    this.setState({ loading: false });
  }

  render() {
    return (
      <main className="auth auth-floated">
        <div className="auth-form d-flex flex-column justify-content-center text-left">
          <h1 className="h3"> Login </h1>
          <p className="mb-0 text-muted ">Entre con su cuenta de mathasa</p>
          <label htmlFor="exampleInputEmail1">Email</label>
          <input
            type="email"
            className="form-control"
            aria-describedby="emailHelp"
            onKeyUp={(ev) => this.inputHandler(ev, "login_data")}
          />
          <small id="emailHelp" className="form-text text-muted">
            Solo personal autorizado puede ingresar
          </small>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Password</label>
            <input
              type="password"
              className="form-control"
              onKeyUp={(ev) => this.inputHandler(ev, "password")}
            />
          </div>
          {this.props.loading && (
            <p className="text-danger">{this.props.loading}</p>
          )}
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.loginHandler}
            disabled={this.state.loading}
          >
            Iniciar sesión
          </button>
          <CircleLoader
            className={this.state.loading ? "opacity_5" : "opacity_0"}
          />
        </div>

        <div id="announcement" className="auth-announcement">
          <img src={Logo} alt="Retrouville logo" />
        </div>
      </main>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, isSignup) =>
      dispatch(actions.auth(email, password, isSignup)),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
