import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({ pagination }) => (
  <ul className="pagination justify-content-center mt-4">
    <Navigation pagination={pagination} side="back" />
    <Pages pag={pagination} />
    <Navigation pagination={pagination} side="next" />
  </ul>
);

const Pages = ({ pag, amount = 2 }) => {
  const Item = ({ pagina, active }) => (
    <li className={`page-item ${pagina === active && "active"}`}>
      <Link className="page-link" to={`/orders/${pagina}`}>
        {pagina}
      </Link>
    </li>
  );
  const ret = [];
  const min = pag - amount;
  const max = pag + amount;
  for (let i = min; i <= max; i++) {
    if (i <= 0) {
      continue;
    }
    ret.push(<Item key={`pages_${i}`} pagina={i} active={pag} />);
  }
  return ret;
};

const Navigation = ({ pagination, side = "back" }) => {
  if (pagination <= 1 && side === "back") {
    return null;
  }
  let pag, arrow, text;
  if (side === "back") {
    pag = pagination - 1;
    arrow = "fa-angle-double-left";
    text = "Atrás";
  } else {
    pag = pagination + 1;
    arrow = "fa-angle-double-right";
    text = "Siguiente";
  }
  return (
    <React.Fragment>
      {side === "back" && (
        <li className="page-item ">
          <Link className="page-link" to={`/orders/1`}>
            <i className={`fa ${arrow}`}></i>
          </Link>
        </li>
      )}
      <li className="page-item ">
        <Link className="page-link" to={`/orders/${pag}`}>
          {text}
        </Link>
      </li>
    </React.Fragment>
  );
};

export default Pagination;
