import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../store/actions";
import navData from "../data/asideData.json";
import { has_access } from "../middleware/page_permissions";

class Aside extends Component {
  state = { user: {} };
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user.name) user.name = user.email;
    this.setState({ user });
  }
  toggleNavMob = () => {
    const mobNav = document.getElementById("mob-menu");
    const backdrop = document.getElementById("aside-backdrop");
    mobNav.classList.remove("show");
    backdrop.classList.remove("show");
  };
  logOutHandle = () => {
    this.props.logout();
    setTimeout(() => {
      window.location.href = "/";
    }, 400);
  };

  render() {
    return (
      <aside
        className="app-aside app-aside-light app-aside-expand-md"
        id="mob-menu"
      >
        <Header user={this.state.user} logout={this.logOutHandle} />
        <nav
          id="stacked-menu"
          className="stacked-menu stacked-menu-has-collapsible"
        >
          <ul className="menu">
            {navData.map((item, key) => {
              if (item.priv && !has_access(item.priv)) return null;
              return (
                <ListItemNav
                  name={item.name}
                  link={item.link}
                  key={key}
                  children={item.children}
                  icon={item.icon}
                  clickFun={this.toggleNavMob}
                />
              );
            })}
          </ul>
        </nav>
      </aside>
    );
  }
}

const ListItemNav = ({ name, link, icon, clickFun, children }) => (
  <li className="menu-item">
    <Link
      className="btn text-capitalize menu-link text-left"
      to={link}
      onClick={clickFun}
    >
      {renderIcons(icon)}
      {name}
    </Link>
    {children ? <ChildrenItem children={children} /> : null}
  </li>
);

const ChildrenItem = ({ children }) => (
  <ul className="menu">
    {children.map((item, key) => (
      <li className="menu-item" key={key}>
        <Link to={item.link} className="menu-link">
          {item.name}
        </Link>
      </li>
    ))}
  </ul>
);

const Header = ({ user, logout }) => (
  <header className="aside-header d-block d-md-none">
    <button
      className="btn-account"
      type="button"
      data-toggle="collapse"
      data-target="#dropdown-aside"
    >
      <span className="account-summary">
        <span className="account-name">{user.name}</span>
        <span className="account-description">Editor</span>
      </span>
    </button>

    <div id="dropdown-aside" className="dropdown-aside collapse">
      <div className="pb-3">
        <button className="dropdown-item text-danger" onClick={logout}>
          Cerrar Sesión
        </button>
      </div>
    </div>
  </header>
);

function renderIcons(icon) {
  if (icon.length < 2) {
    return <i className={`mr-2 ${icon}`}></i>;
  }
  return icon.map((i, key) => (
    <i key={key} className={`${key === 0 ? "" : "mr-2"} ${i}`}></i>
  ));
}

const mapDispatchtoProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(null, mapDispatchtoProps)(Aside);
