import React, { useEffect, useState } from "react";

import { Header } from "../components/side_components";
import firebase, { db } from "../../../middleware/firebase";
import { FormGroup } from "../components/forms";

const PromotionData = ({ promotion_data, disSelect, fetchPromotions }) => {
  const [promotion, setPromotion] = useState(false);

  useEffect(() => {
    setPromotion(promotion_data);
  }, [promotion_data]);

  const handleType = (ev) => {
    const new_promotion = { ...promotion };
    const type = ev.target.id;
    let value = ev.target.value;
    value = value && !isNaN(value) ? parseInt(value) : value;
    new_promotion[type] = value;
    setPromotion(new_promotion);
  };

  const savePromotion = async (ev) => {
    const saleCollection = db.collection("sale_products");
    const productsCollRef = db.collection("products");

    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";

    try {
      const currentProduct = { ...promotion };

      const productsSnapshot = await productsCollRef
        .where("idpart", "==", currentProduct.idpart.toString())
        .get();

      if (productsSnapshot.empty) {
        console.log("No matching documents.");
        button.classList.remove("disabled");
        button.textContent = "Guardar";
        return;
      }
      const newData = { ...currentProduct, ...productsSnapshot.docs[0].data() };

      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      newData.m_timestamp = timestamp;
      newData.m_id = newData.id;

      await saleCollection.doc(promotion.id).set({ ...newData });

      button.classList.remove("disabled");
      button.textContent = "Guardar";
      fetchPromotions();
      disSelect();
    } catch (error) {
      console.log("promotion.js:87 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  return (
    <div className="page-sidebar bg-light p-3">
      <Header title={promotion.descp1} />
      {promotion.m_img ? (
        <img src={promotion.m_img} className="img-200 mb-2" alt="img" />
      ) : promotion.images ? (
        <img src={promotion.images[0]} className="img-200 mb-2" alt="img" />
      ) : null}
      <div className="card">
        <div className="card-body">
          <FormGroup
            handleType={handleType}
            value={promotion.idpart || ""}
            id="idpart"
            label="id del Producto"
          />
          <FormGroup
            handleType={handleType}
            value={promotion.m_img || ""}
            id="m_img"
            icon="fas fa-window-maximize"
            label="URL Imagen"
          />
          <div className="row justify-content-between">
            <div className="col-md-3">
              {" "}
              <button
                className="btn btn-block btn-outline-dark "
                onClick={disSelect}
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-6">
              {" "}
              <button
                className="btn btn-block btn-success"
                onClick={savePromotion}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionData;
