import React from "react";

export const ListItem = ({ title, help, id, letter, handleSelect }) => (
  <div
    className="list-group-item find-list hovered "
    id={id}
    data-sidebar="show"
    onClick={handleSelect}
  >
    <div className="list-group-item-figure">
      <div className="tile tile-circle bg-blue"> {letter} </div>{" "}
    </div>
    <div className="list-group-item-body">
      <h4 className="list-group-item-title ">
        <span> {title}</span>{" "}
      </h4>
      <p className="list-group-item-text">{help}</p>
    </div>
  </div>
);
