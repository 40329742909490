import React from "react";

const BannerList = ({ banners }) => (
  <div className="card">
    <div className="list-group list-group-messages list-group-flush list-group-bordered">
      {banners.map((item) => (
        <BannerItem banner={item} key={item.id} />
      ))}
    </div>
  </div>
);

const BannerItem = ({ banner }) => (
  <div className="list-group-item read">
    <div className="list-group-item-body pl-md-2">
      <div className="row">
        <div className="col-12 col-lg-3 d-none d-lg-block">
          <h4 className="list-group-item-title text-truncate">{banner.name}</h4>
        </div>

        <div className="col-12 col-lg-9">
          <h4 className="list-group-item-title text-truncate">
            Link {banner.to}
          </h4>
        </div>
      </div>
    </div>

    <div className="list-group-item-figure">
      <button className="btn btn-primary">Editar</button>
    </div>
  </div>
);

export default BannerList;
