import React, { useEffect, useState } from "react";

import axios from "../../../middleware/axios";
import Header from "../components/header";
import { db } from "../../../middleware/firebase";
import { Toast } from "../components/alerts";
import { InputGroup } from "../components/forms";
import { CircleLoader } from "../components/loader_ui";
import { ListItem } from "../../common/lists";

import UserSection from "./user";

const UsersPage = () => {
  const [selectedUser, setSelectedUser] = useState(false);
  const [users, setUsers] = useState(false);
  const [typeData, setTypeData] = useState({});
  const [toast, setToast] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(false);
      }, 5000);
    }
  }, [toast]);

  const fetchUsers = async () => {
    const usersRef = db.collection("users");
    const set_users = [];
    const snapshot = await usersRef
      .orderBy("createdOn", "desc")
      .limit(30)
      .get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      return;
    }
    snapshot.forEach((doc) => {
      const user_data = doc.data();
      user_data.id = doc.id;
      set_users.push(user_data);
    });
    setUsers(set_users);
  };

  const handleSelect = (ev) => {
    const user_id = ev.currentTarget.id;
    fetchUser(false, user_id);
  };

  const fetchUser = async (ev, user_id = false) => {
    setLoading(true);
    let searchData = {};
    if (user_id) {
      searchData = {
        type: "user_id",
        string: user_id,
      };
    } else {
      searchData = {
        type: "email",
        string: typeData.user_email,
      };
    }
    try {
      const response = await axios.post("scriptsUsersGetUsers", {
        ...searchData,
      });
      if (response.data.id) {
        setSelectedUser(response.data);
      } else {
        setToast({
          txt: "No existe usuario con ese email",
          type: "danger",
        });
        setSelectedUser(false);
      }
      setLoading(false);
    } catch (error) {
      setToast({
        txt: "Ha ocurrido un error",
        type: "danger",
      });
      console.log("index.js:20 | error ", error);
      setLoading(false);
    }
  };

  const disSelect = () => {
    setSelectedUser(false);
  };

  const handleType = (ev) => {
    const value = ev.target.value;
    const type = ev.target.id;
    const current_type = { ...typeData };
    current_type[type] = value;
    setTypeData(current_type);
  };

  const clearType = (ev) => {
    const type = ev.currentTarget.dataset.id;
    const current_type = { ...typeData };
    current_type[type] = "";
    setTypeData(current_type);
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page ">
          <div className="page-inner  position-relative px-2 px-md-3">
            <Header title="Usuarios" />
            <div className="row">
              <div className="col-md-4">
                <InputGroup
                  value={typeData.user_email}
                  label="Busca usuarios por email"
                  placeholder="usuario@mail.com"
                  id="user_email"
                  handleType={handleType}
                  clear={clearType}
                />
                <button
                  className="btn btn-primary btn-block"
                  onClick={fetchUser}
                >
                  Buscar
                </button>
                <h6 className="mt-2"> Ultimos usuarios</h6>
                <div
                  className="list-group list-group-flush list-group-divider border-top hovered"
                  data-toggle="radiolist"
                >
                  {users &&
                    users.map((user) => {
                      console.log("index.js:146 | user", user);
                      return (
                        <ListItem
                          title={user.name || "-"}
                          help={`email: ${user.email}`}
                          handleSelect={handleSelect}
                          key={`user_${user.id}`}
                          id={user.id}
                          letter={user.name ? user.name[0] : "-"}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="col-md-8">
                {selectedUser && (
                  <UserSection data_user={selectedUser} disSelect={disSelect} />
                )}
                {loading && <CircleLoader show />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast
        show={toast.txt ? true : false}
        txt={toast.txt}
        type={toast.type || "success"}
      />
    </main>
  );
};

export default UsersPage;
