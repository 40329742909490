import React, { useEffect, useState } from "react";
import moment from "moment";
import Header from "../components/header";
import { ListItem } from "../../common/lists";
import IdeaSection from "./idea";
import { db } from "../../../middleware/firebase";

const IdeasPage = () => {
  const [ideas, setIdeas] = useState(false);
  const [selectedIdea, setSelectedIdea] = useState(false);

  useEffect(() => {
    fetchIdeas();
    // eslint-disable-next-line
  }, []);

  const fetchIdeas = async () => {
    const ideasRef = db.collection("ideas");
    const snapshot = await ideasRef.get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      return;
    }

    let ideasArray = [];
    snapshot.forEach((doc) => {
      const ideasData = doc.data();
      ideasData.id = doc.id;
      ideasArray.push(ideasData);
    });
    setIdeas(ideasArray);
  };

  const handleSelect = (ev) => {
    const ideaId = ev.currentTarget.id;
    if (ideaId === "new") {
      const NewIdea = {
        id: "new",
        timestamp: moment().format("YYYY-MM-DD"),
        images: [],
        idparts: [],
        title: "",
      };
      setSelectedIdea(NewIdea);
    } else {
      const selected = ideas.find((i) => i.id === ideaId);
      const selectedCopy = { ...selected };
      selectedCopy.timestamp = selected.timestamp.toDate();
      setSelectedIdea(selectedCopy);
    }
  };

  const disSelect = () => {
    setSelectedIdea(false);
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar has-sidebar-fluid has-sidebar-expand-xl">
          <div className="page-inner  position-relative px-2 px-md-3">
            <Header title="Ideas design" />
            <div className="board p-0 ">
              <div
                className="list-group list-group-flush list-group-divider border-top hovered"
                data-toggle="radiolist"
              >
                {ideas && ideas.length > 0 ? (
                  ideas.map((item, index) => {
                    const date = item.timestamp.toDate();
                    return (
                      <ListItem
                        title={item.title ? `${item.title}` : "-"}
                        help={
                          "Modificado: " +
                          date.toLocaleDateString("es-gb", {
                            month: "long",
                            day: "numeric",
                          })
                        }
                        handleSelect={handleSelect}
                        key={index}
                        id={item.id}
                        letter="Ide"
                      />
                    );
                  })
                ) : (
                  <div className="text-center">
                    <p className=" py-3 text-muted">Sin documentos</p>
                    <button
                      type="button"
                      className="btn btn-primary "
                      title="Add new idea"
                      id="new"
                      onClick={handleSelect}
                    >
                      Agregar Nueva Idea
                    </button>
                  </div>
                )}
              </div>
              <button
                type="button"
                className="btn btn-primary btn-floated position-fixed"
                title="Add new idea"
                onClick={handleSelect}
                id="new"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>

          {selectedIdea && (
            <IdeaSection
              ideaData={selectedIdea}
              disSelect={disSelect}
              fetchIdeas={fetchIdeas}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default IdeasPage;
