import React, { useEffect, useState } from "react";

import { Header } from "../components/side_components";
import firebase, { db } from "../../../middleware/firebase";
import { FormGroup } from "../components/forms";

const BestSellersData = ({ productData, disSelect, fetchProducts }) => {
  const [product, setProduct] = useState(false);

  useEffect(() => {
    setProduct(productData);
  }, [productData]);

  const handleType = (ev) => {
    const thisProduct = { ...product };
    const type = ev.target.id;
    let value = ev.target.value;
    value = value && !isNaN(value) ? parseInt(value) : value;
    thisProduct[type] = value;
    setProduct(thisProduct);
  };

  const saveProduct = async (ev) => {
    const bestSellCollRef = db.collection("best_sellers");
    const productsCollRef = db.collection("products");

    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";

    try {
      const currentProduct = { ...product };

      const productsSnapshot = await productsCollRef
        .where("idpart", "==", currentProduct.productId.toString())
        .get();

      if (productsSnapshot.empty) {
        console.log("No matching documents.");
        button.classList.remove("disabled");
        button.textContent = "Guardar";
        return;
      }
      const newData = { ...currentProduct, ...productsSnapshot.docs[0].data() };

      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      newData.timestamp = timestamp;
      newData.idProduct = newData.id;

      await bestSellCollRef.doc(product.id).set({ ...newData });

      button.classList.remove("disabled");
      button.textContent = "Guardar";
      fetchProducts();
      disSelect();
    } catch (error) {
      console.log("product.js:87 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  return (
    <div className="page-sidebar bg-light p-3">
      <Header title={product.descp1} />
      {product.images ? (
        <img src={product.images[0]} className="img-200 mb-2" alt="product" />
      ) : null}
      <p>{product.subtitle}</p>
      <div className="card">
        <div className="card-body">
          <FormGroup
            handleType={handleType}
            value={product.productId || ""}
            id="productId"
            label="Id del Prodcuto"
          />
          <div className="row justify-content-between">
            <div className="col-md-3">
              {" "}
              <button
                className="btn btn-block btn-outline-dark "
                onClick={disSelect}
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-6">
              {" "}
              <button
                className="btn btn-block btn-success"
                onClick={saveProduct}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestSellersData;
