import React, { useEffect, useState } from "react";
import { db } from "../../../middleware/firebase";

const Aggregates = () => {
  const [aggregates, setAggregates] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const aggregatesRef = db.collection("aggregates").doc("idpartAggregate");

    const unsubscribe = aggregatesRef.onSnapshot(
      (snapshot) => {
        if (snapshot.exists) {
          console.log("Document data:", snapshot.data());
          setAggregates(snapshot.data());
          setLoading(false);
        } else {
          console.log("No document found");
          setAggregates(null);
          setLoading(false);

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      },
      (error) => {
        console.error("Error fetching document:", error);
        setAggregates(null);
        setLoading(false);

        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    );

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>iniciando...</p>;
  }

  if (aggregates === null) {
    return null;
  }

  return (
    <div>
      <p>Productos actualizados: {aggregates.count} productos</p>
    </div>
  );
};

export default Aggregates;
