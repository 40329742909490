export function pirvName(priv) {
  priv = parseInt(priv);
  switch (priv) {
    case 1:
      return "Administrador";
    case 2:
      return "Editor";
    case 4:
      return "Lectura";
    case 10:
      return "Administrador de Comunidad";
    case 16:
      return "Sacerdote";

    default:
      return "Usuario";
  }
}
