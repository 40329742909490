import React, { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { db } from "../../../middleware/firebase";

/**
 * Business Logic Object Autocomplete Input
 * @param {{
 * type: "brands" | "categories"
 * onChange: (selected: string[]) => void
 *  }} props
 */
export default function AutocompleteInput({
  type,
  handleType,
  onChange,
  label,
  placeholder,
  id,
  value,
  help,
  classes,
  input_classes,
  multiple,
}) {
  const [elements, setElements] = useState([]);
  const [selected, setSelected] = useState(
    value && multiple ? value : value ? [value] : []
  );
  const stringSelected = JSON.stringify(selected);
  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(selected);
    }
  }, [selected, onChange, stringSelected]);
  useEffect(() => {
    async function getAndSetElements() {
      if (type === "brands") {
        const docs = (await db.collection("brands").get()).docs;
        setElements(docs.map((doc) => doc.data().brand));
      } else if (type === "categories") {
        const docs = (await db.collection("product-tags").get()).docs;
        const elems = [];
        docs
          .map((x) => x.data())
          .forEach(({ primaryType, secondaryType }) => {
            elems.push(primaryType);
            elems.push(`${primaryType} > ${secondaryType}`);
          });
        setElements([...new Set(elems)]);
      }
    }
    getAndSetElements();
  }, [type]);
  console.log({ elements, value, selected });
  if (!elements.length) return "Loading...";
  return (
    <div className={`form-group ${classes}`}>
      <label htmlFor={id}>{label}</label>
      <Typeahead
        id="basic-typeahead-multiple"
        labelKey="name"
        selected={selected}
        multiple={multiple}
        onChange={(selected) => {
          setSelected(selected);
          handleType({
            target: {
              id,
              value: multiple ? selected : selected[0],
            },
          });
        }}
        placeholder={placeholder}
        options={elements}
      />
      {help && (
        <small id={`${id}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );
}
