import React from "react";
import { Link } from "react-router-dom";

const Header = ({ classes, back, title, button }) => (
  <header className={`page-title-bar ${classes}`}>
    {back ? <Nav back={back} /> : null}
    <div className="d-flex justify-content-between">
      <h1 className="page-title">{title}</h1>
      {button ? <Button button={button} /> : null}
    </div>
  </header>
);

const Nav = ({ back }) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      <li className="breadcrumb-item active">
        <Link to={back.link}>
          <i className="breadcrumb-icon fa fa-angle-left mr-2"></i>
          {back.txt}
        </Link>
      </li>
    </ol>
  </nav>
);

const Button = ({ button }) => {
  const classes = button.classes ? button.classes.join(" ") : " btn-secondary";
  if (button.type === "link") {
    return (
      <Link className={`btn ${classes}`} to={button.link}>
        {button.txt}
      </Link>
    );
  }
  return (
    <button
      type="button"
      className={`btn ${classes}`}
      onClick={button.clickFun}
    >
      {button.txt}
    </button>
  );
};

export default Header;
