import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { db } from "../../../middleware/firebase";
import { ListItem } from "../../common/lists";
import Header from "../components/header";
import IconData from "./iconsData";

const ProductIcons = () => {
  const [icons, setIcons] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(false);
  const { past } = useParams();
  useEffect(() => {
    fetchIcons();
    // setSelectedIcon(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [past]);

  const fetchIcons = async () => {
    const iconsRef = db.collection("icons");
    const set_icons = [];
    const operator = past ? "<" : ">";
    const start = new Date();
    const snapshot = await iconsRef.where("valid", operator, start).get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      setIcons([]);
      return;
    }

    snapshot.forEach((doc) => {
      const icon_data = doc.data();
      icon_data.id = doc.id;
      set_icons.push(icon_data);
    });
    setIcons(set_icons);
  };

  const handleSelect = (ev) => {
    const icon_id = ev.currentTarget.id;
    if (icon_id === "new") {
      const new_icon = {
        id: "new",
      };
      setSelectedIcon(new_icon);
    } else {
      const selected = icons.find((i) => i.id === icon_id);
      const selected_copy = { ...selected };
      setSelectedIcon(selected_copy);
    }
  };

  const disSelect = () => {
    setSelectedIcon(false);
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar has-sidebar-fluid has-sidebar-expand-xl">
          <div className="page-inner  position-relative px-2 px-md-3">
            <Header title="Iconos de Productos" />
            <div className="board p-0 ">
              <div
                className="list-group list-group-flush list-group-divider border-top hovered"
                data-toggle="radiolist"
              >
                {console.log("index.js:68 | icons", icons)}
                {icons.length > 0 ? (
                  icons.map((item) => {
                    const date = item.valid.toDate();

                    return (
                      <ListItem
                        title={item.name}
                        handleSelect={handleSelect}
                        key={`icon_${item.id}`}
                        id={item.id}
                        help={
                          "Creado:" +
                          date.toLocaleDateString("es-gb", {
                            month: "long",
                            day: "numeric",
                          })
                        }
                        letter={getLetter(item.name)}
                      />
                    );
                  })
                ) : (
                  <div className="text-center">
                    <p className=" py-3 text-muted">Sin Iconos Activos</p>
                    <button
                      type="button"
                      className="btn btn-primary "
                      title="Add new icon"
                      id="new"
                      onClick={handleSelect}
                    >
                      Agregar Nuevo
                    </button>
                  </div>
                )}
              </div>
              <button
                type="button"
                className="btn btn-primary btn-floated position-fixed"
                title="Add new cupon"
                onClick={handleSelect}
                id="new"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>
          {selectedIcon && (
            <IconData
              icon_data={selectedIcon}
              disSelect={disSelect}
              fetchIcons={fetchIcons}
            />
          )}
        </div>
      </div>
    </main>
  );
};

function getLetter(type) {
  switch (type) {
    case "percentage":
      return "%";

    default:
      return type[0];
  }
}

export default ProductIcons;
