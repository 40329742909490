import React, { useEffect, useState } from "react";

import { db } from "../../../middleware/firebase";
import Header from "../components/header";
import { ListItem } from "../../common/lists";

import JobSection from "./job";

const JobsPage = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(false);

  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchJobs = async () => {
    const snapshot = await db.collection("cron_jobs").get();
    const thisJobs = [];

    if (snapshot.empty) {
      console.log("No matching documents.");
      return;
    }

    snapshot.forEach((doc) => {
      const job = doc.data();
      job.id = doc.id;
      console.log("job.run_date ", job.run_date);
      thisJobs.push(job);
    });

    thisJobs.sort((a, b) => {
      return new Date(a.run_date.seconds) - new Date(b.run_date.seconds);
    });

    setJobs(thisJobs);
  };

  const handleSelect = (ev) => {
    const jobId = ev.currentTarget.id;
    if (jobId === "new") {
      const newJob = {
        id: "new",
      };
      setSelectedJob(newJob);
    } else {
      const selected = jobs.find((i) => i.id === jobId);
      const selected_copy = { ...selected };
      setSelectedJob(selected_copy);
    }
  };

  const disSelect = () => {
    setSelectedJob(false);
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar has-sidebar-fluid has-sidebar-expand-xl">
          <div className="page-inner  position-relative px-2 px-md-3">
            <Header title="Tareas programadas" />
            <div className="board p-0 ">
              <div
                className="list-group list-group-flush list-group-divider border-top hovered"
                data-toggle="radiolist"
              >
                {jobs && jobs.length > 0 ? (
                  jobs.map((item, index) => {
                    const runDate = item.run_date
                      .toDate()
                      .toLocaleString("es-MX");
                    index = index + 1;
                    return (
                      <ListItem
                        title={item.description}
                        help={`${runDate} ${item.title}`}
                        handleSelect={handleSelect}
                        key={`job_${item.id}`}
                        id={item.id}
                        letter={index}
                      />
                    );
                  })
                ) : (
                  <div className="text-center">
                    <p className=" py-3 text-muted">
                      No hay tareas programadas
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary "
                      title="Add new task"
                      id="new"
                      onClick={handleSelect}
                    >
                      Agregar Nueva Tarea
                    </button>
                  </div>
                )}
              </div>
              <button
                type="button"
                className="btn btn-primary btn-floated position-fixed"
                title="Add new task"
                onClick={handleSelect}
                id="new"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>

          {selectedJob && (
            <JobSection
              jobData={selectedJob}
              disSelect={disSelect}
              fetchJobs={fetchJobs}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default JobsPage;
