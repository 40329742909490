import React, { Component } from "react";
import Header from "./pages/components/header";
import { RichButton } from "./pages/components/buttons";
import navData from "../data/asideData.json";
import { has_access } from "../middleware/page_permissions";
class Dashboard extends Component {
  render() {
    return (
      <main className="app-main">
        <div className="wrapper">
          <div className="page ">
            <div className="page-inner px-2 px-md-3">
              <Header title="Home" />
              <div className="cards-home">
                {navData.map((item, key) => {
                  if ((item.priv && !has_access(item.priv)) || key === 0)
                    return null;
                  return (
                    <RichButton
                      classes="card-width"
                      key={`dash_RichButton_${key}`}
                      to={item.link}
                      icon={item.icon[0]}
                      bgColor="blue"
                      title={item.name}
                      desc={item.desc}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Dashboard;
