import React, { useEffect, useState } from "react";

import { Header } from "../components/side_components";
import { db } from "../../../middleware/firebase";
import { FormGroup, InputGroup } from "../components/forms";
import { ModalDefault } from "../components/modals";

const MesData = ({ mes_data, disSelect, fetchMeses }) => {
  const [mes, setMes] = useState({ ...mes_data });
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setMes(mes_data);
  }, [mes_data]);

  const handleType = (ev) => {
    const new_mes = { ...mes };
    const type = ev.target.id;
    let value = ev.target.value;
    if (type === "comission") value = Number(value / 100);
    new_mes[type] = value;
    setMes(new_mes);
  };

  const saveMes = async (ev) => {
    const collection = db.collection("meses");
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";
    const mes_data = { ...mes };
    mes_data.meses = Number(mes.meses);
    mes_data.comission = Number(mes.comission);
    try {
      const current_mes = { ...mes };
      if (mes.id !== "new") {
        await collection.doc(mes.id).set({ ...mes_data });
      } else {
        const new_mes = await collection.add({ ...mes_data });
        current_mes.id = new_mes.id;
        setMes(current_mes);
      }
      button.classList.remove("disabled");
      button.textContent = "Guardar";
      fetchMeses();
    } catch (error) {
      console.log("mes.js:87 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  const toggleModal = async (ev) => {
    setModal(!modal);
  };
  const removeMes = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    const collection = db.collection("meses").doc(mes.id);
    button.textContent = "Eliminando...";

    try {
      await collection.delete();
      fetchMeses();
      disSelect();
    } catch (error) {
      console.log("mes.js:86 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Eliminar";
    }
  };

  return (
    <div className="page-sidebar bg-light p-3">
      <Header
        title={`Meses: ${mes.meses}, Comisión: ${mes.comission * 100}%`}
      />
      <div className="card">
        <div className="card-body">
          <FormGroup
            handleType={handleType}
            value={mes.meses}
            id="meses"
            type="number"
            label="Meses"
          />
          <InputGroup
            value={mes.comission * 100}
            handleType={handleType}
            id="comission"
            type="number"
            label="Comisión"
            input_classes="form-control-lg"
            badge="%"
          />
          <div className="row justify-content-between">
            <div className="col-md-3">
              {" "}
              <button
                className="btn btn-block btn-outline-dark "
                onClick={disSelect}
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-6">
              {" "}
              <button className="btn btn-block btn-success" onClick={saveMes}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      {mes.id !== "new" && (
        <button
          className="btn btn-block btn-link text-danger"
          onClick={toggleModal}
        >
          Eliminar
        </button>
      )}
      <ModalDefault
        show={modal ? true : false}
        toggle={toggleModal}
        actionFun={removeMes}
        title="¿Borrar mes?"
        action_btn="danger"
        action_txt="Borrar Mes"
      >
        <p>Esta acción no se puede revertir</p>
      </ModalDefault>
    </div>
  );
};

export default MesData;
