import React, { useEffect, useState } from "react";
import { db } from "../../../middleware/firebase";

const Logs = () => {
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const logsRef = db.collection("logs").orderBy("start", "desc").limit(10);
    logsRef.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const logData = change.doc.data();
        console.log("logs.js:10 | logData", logData);
        setLogs((logs) => [...logs, logData]);
      });
    });
  }, []);

  if (logs.length === 0) {
    return null;
  }

  return (
    <table class="table opacity_5">
      <thead class="thead-ligth">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Logs</th>
          <th scope="col">Productos</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log, key) => {
          // const start = new Date(log.start.seconds * 1000);
          // const end = new Date(log.end.seconds * 1000);
          return (
            <tr key={`log_table${key}`}>
              <th scope="row">{key + 1}</th>
              <td>
                {log.message
                  ? log.message.map((m) => <span className="d-block">{m}</span>)
                  : "-"}
              </td>
              <td>{log.productItems ? log.productItems.join(", ") : "-"}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Logs;
