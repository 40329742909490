import React from "react";

export const Header = ({ title, icon, button, subtitle, children }) => (
  <header className="page-title-bar">
    <h1 className="page-title">
      {icon && <i className={`${icon} text-muted mr-2`}></i>} {title}
      {button && (
        <a
          className={`btn btn-outline-dark btn-sm ${button.classes}`}
          href={button.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {button.text} {button.icon && <i className={button.icon}></i>}
        </a>
      )}
    </h1>
    <p className="small">{subtitle}</p>
    {children}
  </header>
);
