import React from "react";

export const CircleLoader = ({ show, className }) => {
  const classes = [
    "loader",
    "d-flex",
    "justify-content-center",
    "align-items-center",
    "p-5"
  ];
  if (className) {
    classes.push(className.split(" "));
  }
  if (show === false) {
    classes.push("opacity_0");
  }
  const color = "primary";
  return (
    <div className={classes.join(" ")}>
      <div className={`spinner-border text-${color}`} role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    </div>
  );
};
