import React, { useEffect, useState } from "react";

import { Header } from "../components/side_components";
import { db } from "../../../middleware/firebase";
import { FormGroup, Select } from "../components/forms";
import { ModalDefault } from "../components/modals";
import moment from "moment";

const jobTypes = [
  {
    text: "Actualizar todos los productos", //title
    value: "scriptsProductsInitialupdate", //job, function
    request: "post",
  },
];

const JobsData = ({ jobData, disSelect, fetchJobs }) => {
  const [job, setJobs] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (jobData.run_date) {
      jobData.run_date = moment(jobData.run_date.toDate()).format(
        "YYYY-MM-DDThh:mm"
      );
    } else {
      jobData.run_date = moment().add(1, "d").format("YYYY-MM-DDTHH:mm");
    }
    if (!jobData.description) {
      jobData.description = "Descripción de la tarea";
    }

    setJobs(jobData);
  }, [jobData]);

  const handleType = (ev) => {
    const newJob = { ...job };
    const type = ev.target.id;
    const value = ev.target.value;
    if (type === "job") {
      const job = jobTypes.find((job) => job.value === value);
      if (job) {
        newJob["title"] = job.text;
        newJob["request"] = job.request;
      }
    } else if (type === "datetime-local") {
      ///validate that date is betweeen 10pm and 6am
      const date = new Date(value);
      const hour = date.getHours();
      if (hour < 22 || hour > 6) {
        alert("La tarea debe programarse entre las 10PM y las 6AM");
        return;
      }
    }
    newJob[type] = value;
    setJobs(newJob);
  };

  const saveJob = async (ev) => {
    const collection = db.collection("cron_jobs");
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";

    try {
      const currentJob = { ...job };

      currentJob.run_date = new Date(currentJob.run_date);
      if (job.id !== "new") {
        await collection.doc(job.id).set({ ...currentJob });
      } else {
        const newJob = await collection.add({ ...currentJob });
        currentJob.id = newJob.id;
      }

      button.classList.remove("disabled");
      button.textContent = "Guardar";
      fetchJobs();
    } catch (error) {
      console.log("job.js:87 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  const toggleModal = async (ev) => {
    setModal(!modal);
  };

  const removeJob = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    const collection = db.collection("cron_jobs").doc(job.id);
    button.textContent = "Eliminando...";

    try {
      await collection.delete();
      fetchJobs();
      disSelect();
    } catch (error) {
      console.log("job.js:86 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Eliminar";
    }
  };

  return (
    <div className="page-sidebar bg-light p-3">
      <Header title={job.title || "Nueva Tarea"} />
      <div className="card">
        <div className="card-body">
          <FormGroup
            handleType={handleType}
            value={job.description}
            id="description"
            label="Título"
            help='Por ejemplo "Actualización precios HotSale"'
          />
          <Select
            value={job.job}
            id="job"
            label="Tarea"
            placeholder="Selecciona la Tarea "
            options={jobTypes}
            handleChange={handleType}
          />
          <FormGroup
            handleType={handleType}
            value={job.run_date || ""}
            id="run_date"
            type="datetime-local"
            label="Fecha programada"
            help="Programar la tarea en un horario de 10PM a 6AM"
          />
          <div className="row justify-content-between">
            <div className="col-md-3">
              {" "}
              <button
                className="btn btn-block btn-outline-dark "
                onClick={disSelect}
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-6">
              {" "}
              <button className="btn btn-block btn-success" onClick={saveJob}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      {job.id !== "new" && (
        <button
          className="btn btn-block btn-link text-danger"
          onClick={toggleModal}
        >
          Eliminar
        </button>
      )}
      <ModalDefault
        show={modal ? true : false}
        toggle={toggleModal}
        actionFun={removeJob}
        title={job.title ? `¿Borrar Tarea ${job.title}?` : ""}
        action_btn="danger"
        action_txt="Borrar Tarea"
      >
        <p>Esta acción no se puede revertir</p>
      </ModalDefault>
    </div>
  );
};

export default JobsData;
