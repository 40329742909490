import React, { useEffect, useState } from "react";
import moment from "moment";

import axios from "../../../middleware/axios";
import { Header } from "../components/side_components";
import { FormGroup, Select } from "../components/forms";
import { ModalDefault } from "../components/modals";

const bool_options = [
  { value: "1", text: "Sí" },
  { value: "0", text: "No" },
];

const UserData = ({ data_user, disSelect }) => {
  console.log("user.js:14 | data_user", data_user);
  const [user, setUser] = useState({ ...data_user });

  const [modal, setModal] = useState(false);

  useEffect(() => {
    data_user.creationTime = moment(data_user.metadata.creationTime).format(
      "D MMM y"
    );
    data_user.lastSignInTime = data_user.metadata.lastSignInTime
      ? moment(data_user.metadata.lastSignInTime).format("D MMM y")
      : "Sin Registro";

    setUser({ ...data_user });
  }, [data_user]);

  if (!user.id) return null;

  const handleType = (ev) => {
    const new_user = { ...user };
    const type = ev.target.id;
    let value = ev.target.value;
    if (type === "admin") value = Boolean(Number(value));
    if (type === "priv") value = Number(value) + 1;
    new_user[type] = value;
    setUser(new_user);
  };

  const saveUser = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";
    const user_data = { ...user };
    user_data.Usuario = Number(user.Usuario);
    user_data.comission = Number(user.comission);
    try {
      await axios.post("scriptsUsersEditUser", {
        user: { ...user, ogEmail: data_user.email },
      });
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    } catch (error) {
      console.log("user.js:87 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  const toggleModal = async (ev) => {
    setModal(!modal);
  };
  const removeUser = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Eliminando...";

    try {
      await axios.post("scriptsUsersDeleteUser", {
        user: { ...user, ogEmail: data_user.email },
      });
      disSelect();
    } catch (error) {
      console.log("user.js:86 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Eliminar";
    }
  };

  return (
    <div className=" bg-light p-3">
      <Header title={`Usuario: ${user.displayName}`}>
        <div className="row">
          <div className="col-8">
            <p className="text-muted">
              <i className="fa-fw mr-2 far fa-calendar"></i> Creado el:{" "}
              {user.creationTime} <br />
              <i className="fa-fw mr-2 fas fa-calendar-day"></i> Úlima vez
              visto: {user.lastSignInTime} <br />
            </p>
          </div>
          <div className="col">
            <p className="text-muted">
              <i className="fa-fw mr-2 fas fa-hashtag"></i>ID Firstada:{" "}
              {user.first_data_id || "Sin ID"} <br />
              <i className="fa-fw mr-2 fas fa-hashtag"></i> ID Advanta:{" "}
              {user.api_id} <br />
            </p>
          </div>
        </div>
      </Header>
      <div className="card">
        <div className="card-body">
          <FormGroup
            label="Nombre"
            id="displayName"
            value={user.displayName}
            handleType={handleType}
          />
          <FormGroup
            label="Email"
            id="email"
            value={user.email}
            handleType={handleType}
          />
          <FormGroup
            label="ID Advanta"
            id="api_id"
            value={`#${user.api_id}`}
            handleType={handleType}
          />
          <FormGroup
            label="Cumpleaños"
            id="birthdate"
            value={user.birthdate}
            type="date"
            handleType={handleType}
          />
          <Select
            label="Es Admin"
            id="admin"
            help="Tiene acceso a este panel de administración"
            value={user.admin ? "1" : "0"}
            options={bool_options}
            handleChange={handleType}
          />

          <Select
            label="Administrar Usuarios"
            id="priv"
            help="Puede crear mas usuarios adminsitradores"
            value={String(user.priv - 1)}
            options={bool_options}
            handleChange={handleType}
          />
          <button className="btn btn-block btn-success" onClick={saveUser}>
            Guardar
          </button>
        </div>
      </div>
      {user.id !== "new" && (
        <button
          className="btn btn-block btn-link text-danger"
          onClick={toggleModal}
        >
          Eliminar
        </button>
      )}
      <ModalDefault
        show={modal ? true : false}
        toggle={toggleModal}
        actionFun={removeUser}
        title="¿Borrar user?"
        action_btn="danger"
        action_txt="Borrar User"
      >
        <p>Esta acción no se puede revertir</p>
      </ModalDefault>
    </div>
  );
};

export default UserData;
