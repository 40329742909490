import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export const ModalDefault = ({
  children,
  title,
  show,
  toggle,
  actionFun,
  action_btn,
  action_txt,
  hideFooter,
}) => (
  <Modal isOpen={show} toggle={toggle} className={hideFooter ? "pb-5" : ""}>
    {title && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
    <ModalBody>{children}</ModalBody>
    {hideFooter ? null : (
      <ModalFooter>
        <Button color="secondary" onClick={toggle} className="btn-outline-dark">
          Cancelar
        </Button>
        <Button color={action_btn || "primary"} onClick={actionFun}>
          {action_txt || "Aceptar"}
        </Button>{" "}
      </ModalFooter>
    )}
  </Modal>
);
