import React from "react";
import { CircleLoader } from "../components/loader_ui";
import Pagination from "../components/pagination";
import { SelectAndText } from "../components/forms";

import {
  formatNumber,
  deliveryIcons,
  paymentIcons,
  timeStampDate,
} from "../../../middleware/common-functions";

const OrdersList = ({
  orders,
  toToOrder,
  update,
  loading,
  pagination,
  search_options,
  button,
  handleFind,
  find_data,
  searched,
  onKeyDownHandler,
}) => (
  <div className="card card-fluid">
    <div
      className="card-header nav-scroller d-flex justify-content-between"
      style={{ overflowY: "visible" }}
    >
      Pedidos{" "}
      <div className="w-50">
        <SelectAndText
          options={search_options}
          help="Buscar pedidos"
          id="search"
          button={button}
          typeValue={find_data.search}
          selectValue={find_data.select_search}
          handleChange={handleFind}
          onKeyDown={onKeyDownHandler}
        />
      </div>
      <button onClick={update} className="btn">
        <i className="fas fa-sync-alt"></i>
      </button>
    </div>

    <div className="tab-content">
      <div className="list-group list-group-flush list-group-divider">
        {loading ? (
          <CircleLoader />
        ) : (
          orders.map((item) => (
            <OrderItem key={item.id} order={item} toToOrder={toToOrder} />
          ))
        )}
      </div>
      {!searched && <Pagination pagination={pagination} />}
    </div>
  </div>
);

const OrderItem = ({ order, toToOrder }) => {
  const shipping_icon = deliveryIcons(order.delivery_type);
  const payment_icon = paymentIcons(
    order.payment_type,
    order.is_payed,
    order.is_fake
  );
  const date = timeStampDate(order.timestamp);
  return (
    <div
      className="list-group-item hover"
      onClick={toToOrder}
      data-order_id={order.id}
      data-user_id={order.user_id}
    >
      <div className="list-group-item-figure">
        <span className="tile tile-circle tile-sm">
          <i className="far fa-calendar-alt fa-lg"></i>
        </span>
      </div>
      <div className="list-group-item-body">
        <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
          <div className="team w-75">
            <h4 className="list-group-item-title">
              <a href="page-team.html">
                {order.user_email}{" "}
                <small className="text-black-50">{date}</small>
                <small className="ml-3 text-black-50">
                  {order.app_id && `#${order.app_id}`}
                </small>
              </a>
            </h4>
            <p className="list-group-item-text"> {order.products_data.desc}</p>
          </div>
          <ul className="list-inline text-muted mb-0 w-25 text-right">
            {"isMobile" in order && (
              <li
                className="list-inline-item"
                data-toggle="tooltip"
                title=""
                data-original-title="Platform"
              >
                {order.isMobile === true ? (
                  <>
                    <i className="fas fa-mobile-alt"></i> Mobile
                  </>
                ) : (
                  <>
                    <i className="fas fa-desktop"></i> Desktop
                  </>
                )}
              </li>
            )}
            <li className="list-inline-item mr-3">
              <i className="fas fa-dollar-sign"></i>
              {formatNumber(order.total)}
            </li>
            <li
              className="list-inline-item mr-3"
              data-toggle="tooltip"
              title=""
              data-placement="bottom"
              data-original-title="Projects"
            >
              <i className={payment_icon}></i>
            </li>
            <li
              className="list-inline-item"
              data-toggle="tooltip"
              title=""
              data-original-title="Public"
            >
              <i className={shipping_icon}></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrdersList;
