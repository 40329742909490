import * as actionTypes from "./action_types";
import firebase, { db } from "../../middleware/firebase";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (user_ud) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    id: user_ud,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const auth = (authData) => {
  return (dispatch) => {
    dispatch(authStart());
    firebase
      .auth()
      .signInWithEmailAndPassword(authData.login_data, authData.password)
      .then(async (data) => {
        const { uid, email, displayName } = data.user;
        let user = { id: uid, email, displayName };
        ///get more user info
        const userRef = db.collection("users").doc(user.id);
        const doc = await userRef.get();
        if (!doc.exists) {
          throw new Error("No existe este usuario");
        }

        const user_data = doc.data();
        user = { ...user, ...user_data };
        if (!user.admin) {
          throw new Error("Este usuario no tiene acceso al administrador");
        }
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(authSuccess(user.id));
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("auth.js:40 | errorCode", errorCode);
        console.log("auth.js:41 | errorMessage", errorMessage);
        dispatch(authFail(error.message));
        return;
      });
  };
};

export const logout = () => {
  localStorage.clear();

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    let user = localStorage.getItem("user");
    // const auth = firebase.auth().currentUser;
    if (!user) {
      dispatch(logout());
      return false;
    } else {
      user = JSON.parse(user);
      dispatch(authSuccess(user.id));
      return true;
    }
  };
};
