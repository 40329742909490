import React from "react";
import { formatNumber } from "../../../middleware/common-functions";

const ProductList = ({ products }) => (
  <div className="table-responsive">
    <table className="table">
      <thead>
        <tr>
          <th> Producto </th>
          <th className="text-center"> Cantidad </th>
          <th className="text-center"> Precio Unitario</th>
          <th className="text-center"> Total</th>
        </tr>
      </thead>

      <tbody>
        {products.map((item, key) => (
          <ProductRow product={item} key={item.id} />
        ))}
      </tbody>
    </table>
  </div>
);

const ProductRow = ({ product }) => (
  <tr>
    <td className="align-middle text-truncate">
      <a
        href={`https://mathasa.mx/product/${product.id}`}
        target="_blank"
        rel="noopener noreferrer"
        className="mr-2"
      >
        <img
          src={product.image}
          style={{ width: "50px" }}
          alt={`https://mathasa.mx/product/${product.id}`}
        />
      </a>{" "}
      <a
        href={`https://mathasa.mx/product/${product.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {product.title}
      </a>
    </td>
    <td className="align-middle text-center">
      {formatNumber(product.cant, false)}
    </td>
    <td className="align-middle text-center">
      {" "}
      ${formatNumber(product.price)}{" "}
    </td>
    <td className="align-middle text-center">
      {" "}
      ${formatNumber(product.price * product.cant)}{" "}
    </td>
  </tr>
);

export default ProductList;
