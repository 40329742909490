import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { pirvName } from "./pages/components/commonfun";
import * as actions from "../store/actions";
import Logo from "../assets/logo_mathasa _full_w.png";

class Nav extends Component {
  state = { user: {}, toggled: false, toggledNav: false };
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({ user });
  }
  toggle = () => {
    this.setState((prevState) => {
      const newToggled = !prevState.toggled;
      return { toggled: newToggled };
    });
  };

  logOutHandle = () => {
    this.props.logout();
    setTimeout(() => {
      window.location.href = "/";
    }, 400);
  };

  toggleNavMob = () => {
    const mobNav = document.getElementById("mob-menu");
    const backdrop = document.getElementById("aside-backdrop");
    mobNav.classList.add("show");
    backdrop.classList.add("show");
  };

  render() {
    return (
      <header className="app-header app-header-dark">
        <div className="top-bar">
          <div className="top-bar-brand">
            <Link to="/">
              <img src={Logo} height="32px" alt="" />
            </Link>
          </div>
          <MobNav clickFun={this.toggleNavMob} />

          <LeftItems
            user={this.state.user}
            toggled={this.state.toggled}
            clickFun={this.toggle}
            logOut={this.logOutHandle}
          />
        </div>
      </header>
    );
  }
}
const MobNav = ({ clickFun }) => (
  <div className="top-bar-item px-2 d-md-none d-lg-none d-xl-none">
    <button
      className="hamburger hamburger-squeeze"
      type="button"
      data-toggle="aside"
      aria-label="toggle menu"
      onClick={clickFun}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  </div>
);

const LeftItems = ({ user, toggled, clickFun, logOut }) => (
  <div className="top-bar-item top-bar-item-right px-0 d-none d-sm-flex">
    <div className={`dropdown d-flex ${toggled ? "show" : ""}`}>
      <button
        className="btn-account d-none d-sm-flex"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={clickFun}
      >
        <span className="account-summary pr-lg-4 d-none d-sm-block">
          <span className="account-name">{user.displayName}</span>{" "}
          <span className="account-description">{pirvName(user.priv)}</span>
        </span>
      </button>

      <div className={`dropdown-menu ${toggled ? "show" : ""}`}>
        <div className="dropdown-arrow ml-3"></div>
        <button className="dropdown-item" onClick={logOut}>
          <span className="dropdown-icon oi oi-account-logout"></span> Logout
        </button>
      </div>
    </div>
  </div>
);

const mapDispatchtoProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(null, mapDispatchtoProps)(Nav);
