import React, { useEffect, useState } from "react";
import moment from "moment";
import firebase, { db } from "../../../middleware/firebase";
import { ButtonNested } from "../components/buttons.js";
import axios from "../../../middleware/axios";
import ProductList from "./products";
import ShippingData from "./shipping";
import {
  formatNumber,
  paymentIcons,
  deliveryIcons,
  renderDelivery,
  timeStampDate,
} from "../../../middleware/common-functions";
import Timeline from "../../common/timeline";
import { InputGroup, TextArea } from "../components/forms";
import { Alert, Toast } from "../components/alerts";
import { ModalDefault } from "../components/modals";
import { Link, useParams } from "react-router-dom";

const OrderPage = () => {
  const { order_id, user_id } = useParams();
  const [order, setOrder] = useState(false);
  const [history, setHistory] = useState(false);
  const [api_id, setApi_id] = useState(false);
  const [modal, setModal] = useState(false);
  const [orderMsg, setOrderMsg] = useState("");
  const [modalPayOrder, setModalPayOrder] = useState(false);
  const [toast, setToast] = useState({});
  console.log("index.js:30 | order", order);
  useEffect(() => {
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(false);
      }, 5000);
    }
  }, [toast]);

  const toggleModal = async (ev) => {
    setModal(!modal);
  };

  const handleApi_id = async (ev) => {
    let value = ev.target.value;
    if (value) {
      value = value.toUpperCase();
    }
    setApi_id(value);
  };

  const handleOrderMsg = async (ev) => {
    let value = ev.target.value;
    setOrderMsg(value);
  };

  const saveOrderId = async (ev) => {
    const collection = db
      .collection(`/orders/${user_id}/orders/`)
      .doc(order_id);
    const order_list = db.collection(`/order_list`).doc(order_id);
    if (!api_id) return false;
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";
    const order_data = { ...order };
    try {
      await collection.set({ api_id: api_id }, { merge: true });
      await order_list.set({ app_id: api_id }, { merge: true });
      order_data.api_id = api_id;
      button.classList.remove("disabled");
      button.textContent = "Guardar";
      setOrder(order_data);
      toggleModal();
    } catch (error) {
      console.log("index.js:44 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  const payOrderId = async (ev) => {
    const collection = db
      .collection(`/orders/${user_id}/orders/`)
      .doc(order_id);
    const order_list = db.collection(`/order_list`).doc(order_id);
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";
    const order_data = { ...order };
    try {
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      await collection.set(
        {
          is_payed: timestamp,
          order_transfer_msg: orderMsg,
        },
        { merge: true }
      );
      await order_list.set(
        {
          is_payed: timestamp,
          order_transfer_msg: orderMsg,
        },
        { merge: true }
      );
      order_data.is_payed = true;
      button.classList.remove("disabled");
      button.textContent = "Guardar";
      setOrder(order_data);
      setModalPayOrder(!modalPayOrder);
    } catch (error) {
      console.log("index.js:44 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  const getOrder = async () => {
    const orderRef = db.collection(`/orders/${user_id}/orders/`).doc(order_id);
    const doc = await orderRef.get();

    if (!doc.exists) {
      console.log("No such document!");
    } else {
      const order_data = doc.data();
      setOrder({ ...order_data });
      setApi_id(order_data.api_id);
      getMailHistory(order_data.user_email, order_data.timestamp);
    }
  };

  const getMailHistory = async (user_email, timestamp) => {
    console.log("index.js:117 | timestamp", timestamp);
    let fromdate = timeStampDate(timestamp, "en-GB", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    console.log("index.js:122 | fromdate", fromdate);
    fromdate = moment(fromdate).format("YYYY-MM-DD");
    try {
      const { data } = await axios.post("scriptsMailGetHistory", {
        user_email,
        fromdate,
      });
      if (data.error) {
        return false;
      }
      setHistory(data.response.Messages);
    } catch (error) {
      console.log("index.js:114 | error ", error);
    }
  };

  const toggleOrderCancelled = async (ev) => {
    const button = ev.currentTarget;
    button.classList.add("disabled");
    button.disabled = true;
    button.textContent = "Cancelando...";
    try {
      const orderDoc = db
        .collection(`/orders/${user_id}/orders/`)
        .doc(order_id);
      await orderDoc.update({ cancelled: !Boolean(order && order.cancelled) });
      window.location.reload();
      return;
    } catch (error) {
      console.log("index.js:102 | error ", error);
      setToast({
        txt: "Algo salió mal, favor de contactar a contacto@vamedia.co",
        type: "danger",
      });
      button.disabled = false;
      button.classList.remove("disabled");
      button.textContent = "Enviar Recordatorio";
    }
  };

  const sendPayReminder = async (ev) => {
    const button = ev.currentTarget;
    button.classList.add("disabled");
    button.disabled = true;
    button.textContent = "Enviando...";
    try {
      const response = await axios.post("scriptsPaySendReminder", {
        user_id,
        order_id,
      });
      if (response.error) {
        throw response.error;
      }
      setToast({
        txt: "Recordatorio enviado al Cliente",
        type: "success",
      });
      button.classList.add("btn-link");
      button.textContent = "Recordatorio enviado";
    } catch (error) {
      console.log("index.js:102 | error ", error);
      setToast({
        txt: "Algo salió mal, favor de contactar a contacto@vamedia.co",
        type: "danger",
      });
      button.disabled = false;
      button.classList.remove("disabled");
      button.textContent = "Enviar Recordatorio";
    }
  };

  if (!order) {
    return "Cargando...";
  }

  const optionsList = [
    { name: "Enviar Recordatorio", click: sendPayReminder },
    {
      name: order && order.cancelled ? "Re-activar Orden" : "Cancelar ",
      click: toggleOrderCancelled,
    },
  ];

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page">
          <div className="page-inner">
            <header className="page-title-bar">
              <div className="d-flex justify-content-between align-items-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to="/orders">
                        <i className="breadcrumb-icon fa fa-angle-left mr-2"></i>
                        Volver a Órdenes
                      </Link>
                    </li>
                  </ol>
                </nav>
                <button
                  type="button"
                  className="btn btn-light btn-icon d-xl-none"
                  data-toggle="sidebar"
                >
                  <i className="fa fa-angle-double-left"></i>
                </button>
                <div>
                  <button className="btn btn-link" onClick={toggleModal}>
                    {" "}
                    #{order.api_id} <i className="far fa-edit"></i>
                  </button>
                  <span>
                    <i className="ml-3 fas fa-calendar"></i>{" "}
                    {timeStampDate(order.timestamp)}
                  </span>
                  {order.coupon && (
                    <span>
                      <i className="ml-3 fas fa-ticket-alt"></i>{" "}
                      {order.coupon.code}
                    </span>
                  )}
                  {"isMobile" in order && (
                    <span>
                      {order.isMobile === true ? (
                        <>
                          <i className="ml-3 fas fa-mobile-alt"></i> Mobile
                        </>
                      ) : (
                        <>
                          <i className="ml-3 fas fa-desktop"></i> Desktop
                        </>
                      )}
                    </span>
                  )}
                </div>
              </div>

              {order.is_fake ? (
                <Alert icon="fas fa-skull-crossbones" color="danger">
                  Este pedido no tiene registrado el pago <br />
                  Favor de contactar al cliente para corrobar que el pago se
                  haya hecho
                </Alert>
              ) : null}
              <div className="row text-center text-sm-left">
                <div className="col">
                  <h1 className="page-title">
                    {" "}
                    {order.user_email}
                    {order.cancelled && (
                      <span className="badge badge-danger">Cancelado</span>
                    )}
                    {(!order.is_fake && order.payment_type !== "transfer") ||
                    order.is_payed ? (
                      <span className="badge badge-success">Pagada</span>
                    ) : null}
                    {(order.payment_type === "transfer" && !order.is_payed) ||
                    (order.is_fake && !order.is_payed) ? (
                      <ButtonNested
                        txt="Marcar como pagada"
                        btn="btn-outline-secondary"
                        classes="ml-2"
                        options={optionsList}
                        clickFun={() => setModalPayOrder(true)}
                      />
                    ) : null}
                  </h1>
                  <p className="text-muted">
                    {order.products_data.desc} <br />
                    {order.user && order.user.phone ? (
                      <span>
                        <i className="fa-fw fas fa-phone-alt"></i> Teléfono de
                        Contacto: {order.user.phone}
                        <br />
                      </span>
                    ) : null}
                    <i className="fa-fw fas fa-calendar-day"></i>{" "}
                    {renderDeliveryDates(order.shipping)}
                  </p>
                </div>
              </div>
            </header>

            <div className="page-section">
              {order.payment_type === "transfer" &&
              !order.is_payed &&
              !order.cancelled ? (
                <Alert icon="fas fa-file-invoice-dollar" color="warning">
                  Este pedido tiene el pago pendiente <br />
                  El cliente debe enviar un correo con la confirmación del
                  depósito o transacción
                </Alert>
              ) : null}
              <div className="section-block">
                <div className="metric-row metric-flush">
                  <OrderItem
                    title="Total"
                    value={"$" + formatNumber(order.totals.total)}
                    icon="fas fa-money-bill-wave"
                    sub_title={`${
                      order.totals.shipping
                        ? `Costo de Envío: $${formatNumber(
                            order.totals.shipping
                          )} `
                        : ""
                    } ${
                      order.totals.comission
                        ? `Incluye $${formatNumber(
                            order.totals.comission
                          )} de Comisión Pago a Meses `
                        : ""
                    }`}
                    coupon={order.coupon}
                    totals={order.totals}
                  />
                  <OrderItem
                    title="Forma de Entrega"
                    value={order.delivery_type}
                    sub_title={renderDelivery(order.address)}
                    icon={deliveryIcons(order.delivery_type)}
                  />
                  <OrderItem
                    title="Forma de Pago"
                    value={renderPayment(order.payment_type)}
                    icon={paymentIcons(order.payment_type, order.is_payed, "")}
                    sub_title={renderSubtitle(order)}
                  />
                  <OrderItem
                    title="Almacén"
                    value={`${order.selectedBranch.idalma}`}
                    sub_title={`Zona: ${order.selectedBranch.id}`}
                    icon="fas fa-globe-americas"
                  />
                </div>
              </div>
              <div className="row">
                {order.address && (
                  <div className="col-md-4">
                    <ShippingData address={order.address} />
                  </div>
                )}
                <div className={order.address ? "col-md-8" : "col"}>
                  <div className="card card-body">
                    <ProductList products={order.products} />
                  </div>
                </div>
              </div>
              {history && <History data={history} />}
            </div>
          </div>
        </div>
      </div>
      <ModalDefault
        show={modal ? true : false}
        title="Cambiar Número de Orden"
        action_txt="Guardar"
        actionFun={saveOrderId}
        toggle={toggleModal}
      >
        <InputGroup
          value={api_id}
          help="Número de pedido en Advanta"
          id="app_id_val"
          handleType={handleApi_id}
        />
      </ModalDefault>
      <ModalDefault
        show={modalPayOrder ? true : false}
        title="Agregar Mensaje al Cliente"
        action_txt="Guardar"
        actionFun={payOrderId}
        toggle={() => setModalPayOrder(!modalPayOrder)}
      >
        <TextArea
          value={orderMsg}
          type=""
          help="Este mensaje se incluirá en el mail al cliente"
          placeholder="Mensaje: Hemos recibido tu pedido, tu pago fue aprobado. Vamos a empezar a procesar tu orden"
          id="order_transfer_msg"
          handleType={handleOrderMsg}
        />
      </ModalDefault>
      <Toast
        show={toast.txt ? true : false}
        txt={toast.txt}
        type={toast.type || "success"}
        toggle={() => setToast(false)}
      />
    </main>
  );
};

const OrderItem = ({ title, value, icon, sub_title, coupon, totals }) => (
  <div className="col">
    <div
      className="metric metric-bordered align-items-center justify-content-start"
      style={{ height: "100%" }}
    >
      <h4>
        <i className={icon}></i>
      </h4>
      <h3 className="metric-label">{title} </h3>
      <p className="metric-value h3">
        <span className="value text-capitalize">{value}</span>
      </p>
      {sub_title && <p className="metric-label">{sub_title}</p>}
      {coupon && totals.discount && (
        <span>
          <i className="ml-3 fas fa-ticket-alt"></i> -$
          {formatNumber(totals.discount)}
        </span>
      )}
    </div>
  </div>
);

const History = ({ data }) => {
  const history_data = data.map((item) => {
    return {
      icon: "far fa-envelope",
      title: item.Subject,
      sub_title: `De: ${item.From}`,
      date: timeStampDate(item.ReceivedAt),
    };
  });
  return (
    <div className="row">
      <div className="col-md-12">
        <hr />
        <h1 className="page-title">Historial de emails </h1>
        <Timeline items={history_data} empty_txt="Sin Registos de emails" />
      </div>
    </div>
  );
};

function renderPayment(payment) {
  switch (payment) {
    case "online":
      return "Pagado Online";
    case "rewards":
      return "Recompensas";
    case "rappi":
      return "RappiPay";
    case "atrato":
      return "Atrato Pago";
    case "coppel":
      return "CoppelPay";
    case "paypal":
      return "Paypal";
    default:
      return "Transferencia Electrónica";
  }
}

function renderDeliveryDates(shipping) {
  if (!shipping) return false;
  let response = [];
  let package_num = 1;
  if (shipping.in_stock && shipping.in_stock.delivery_date) {
    response.push(
      `Fecha Entrega #${package_num} ${shipping.in_stock.delivery_date}`
    );
    package_num++;
  }
  if (shipping.no_stock && shipping.no_stock.length > 0) {
    shipping.no_stock.forEach((shipping_data) => {
      response.push(
        `Fecha Entrega #${package_num} ${shipping_data.delivery_date}`
      );
      package_num++;
    });
  }
  return response.join(" | ");
}

function renderSubtitle(order) {
  let subtitle = "";
  if (order.rewards) {
    subtitle = `($${formatNumber(order.rewards)} pagado con recompensas)`;
  }
  if (order.payment_type === "transfer") {
    subtitle = order.is_payed
      ? `Pagada ${timeStampDate(order.is_payed)}`
      : "Pago Pendiente";
  } else if (order.ipgTransactionId) {
    subtitle = `Fiserv ID: ${order.ipgTransactionId}`;
  }
  return subtitle;
}

export default OrderPage;
