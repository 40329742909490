import React, { Component } from "react";
import Nav from "./nav";
import Aside from "./navAsaide";
class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <Nav />
        <Aside />
      </React.Fragment>
    );
  }
}

export default Header;
