import React, { useEffect, useState } from "react";

import { Header } from "../components/side_components";
import firebase, { db } from "../../../middleware/firebase";
import { FormGroup, Select, CheckboxGroup } from "../components/forms";
import { ModalDefault } from "../components/modals";

const websites = [
  "decasahermosillo.com",
  "decasajuarez.com",
  "decasa.mx",
  "idecasa.mx",
  "mathasa.mx",
  "designmathasa.mx",
];

const categories = [
  {
    text: "Predeterminado (se mostrará en las categorías sin banner asignado",
    value: "Default",
  },
  {
    text: "Adhesivos",
    value: "Adhesivos",
  },
  {
    text: "Aires y minisplits",
    value: "Aires-y-minisplits",
  },
  {
    text: "Baño",
    value: "Bano",
  },
  {
    text: "Boilers",
    value: "Boilers",
  },
  {
    text: "Calefactores",
    value: "Calefactores",
  },
  {
    text: "Cocina",
    value: "Cocina",
  },
  {
    text: "Grifería",
    value: "Griferia",
  },
  {
    text: "Herramientas",
    value: "Herramientas",
  },
  {
    text: "Hogar",
    value: "Hogar",
  },
  {
    text: "Iluminación",
    value: "Iluminacion",
  },
  {
    text: "Institucional",
    value: "Institucional",
  },
  {
    text: "Mosaicos",
    value: "Mosaicos",
  },
  {
    text: "Piedras",
    value: "Piedras",
  },
  {
    text: "Pintura",
    value: "Pintura",
  },
  {
    text: "Pisos",
    value: "Pisos",
  },
  {
    text: "Tinas",
    value: "Tinas",
  },
  {
    text: "Varios",
    value: "Varios",
  },
];

const BannerData = ({ banner_data, disSelect, fetchBanners, zones }) => {
  const [banner, setBanner] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (!banner_data.websites) banner_data.websites = [];
    banner_data.handle_websites = websites.map((item) => {
      const finded = banner_data.websites.find((web) => web === item);
      return { value: item, checked: Boolean(finded) };
    });
    banner_data.handle_zones = zones.map((item) => {
      let finded = false;
      if (banner_data.zones) {
        finded = banner_data.zones.find((web) => web === item.id);
      }
      return { id: item.id, value: item.descrp, checked: Boolean(finded) };
    });
    setBanner(banner_data);
  }, [banner_data, zones]);

  const handleType = (ev) => {
    const new_banner = { ...banner };
    const type = ev.target.id;
    let value = ev.target.value;
    value = value && !isNaN(value) ? parseInt(value) : value;
    new_banner[type] = value;
    setBanner(new_banner);
  };

  const handleCheckbox = (ev) => {
    const new_banner = { ...banner };
    let value = ev.target.value;
    let type = ev.target.dataset.type; //// websites, zones
    if (!new_banner[type]) {
      new_banner[type] = [];
    }
    if (ev.target.checked) {
      new_banner[type].push(value);
    } else {
      const index = new_banner[type].indexOf(value);
      if (index !== -1) {
        new_banner[type].splice(index, 1);
      }
    }
    setBanner(new_banner);
  };

  const saveBanner = async (ev) => {
    const collection = db.collection("banners");
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";

    try {
      const current_banner = { ...banner };

      delete current_banner.handle_websites;
      delete current_banner.handle_zones;
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      current_banner.timestamp = timestamp;
      current_banner.is_mini_banner = true;
      current_banner.category = current_banner.category || "Default";
      if (banner.id !== "new") {
        await collection.doc(banner.id).set({ ...current_banner });
      } else {
        const new_banner = await collection.add({ ...current_banner });
        current_banner.id = new_banner.id;
      }

      button.classList.remove("disabled");
      button.textContent = "Guardar";
      fetchBanners();
    } catch (error) {
      console.log("banner.js:87 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  const toggleModal = async (ev) => {
    setModal(!modal);
  };
  const removeBanner = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    const collection = db.collection("banners").doc(banner.id);
    button.textContent = "Eliminando...";

    try {
      await collection.delete();
      fetchBanners();
      disSelect();
    } catch (error) {
      console.log("banner.js:86 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Eliminar";
    }
  };

  return (
    <div className="page-sidebar bg-light p-3">
      <Header title={banner.category || "Nuevo Banner"} />
      {banner.id !== "new" && banner.mini_img ? (
        <img src={banner.mini_img} className="img-fluid mb-2" alt="bannerimg" />
      ) : null}
      <div className="card">
        <div className="card-body">
          <Select
            value={banner.category}
            id="category"
            label="Categoría"
            options={categories}
            handleChange={handleType}
            placeholder="Selecciona la categoría"
            help="Categoría en la cual aparecerá el banner"
          />
          <FormGroup
            handleType={handleType}
            value={banner.mini_img || ""}
            id="mini_img"
            label="URL Imagen Mini Banner"
          />
          <FormGroup
            handleType={handleType}
            value={banner.link || ""}
            id="link"
            label="Link de Banner"
            placeholder="Ej: /info/sucursales "
            help="Para links dentro de las páginas de Mathasa.mx, cargar unicamente depués de la primer `/` en la url"
          />
          <CheckboxGroup
            options={banner_data.handle_websites}
            title="Visible en páginas:"
            type="websites"
            hadnleCheck={handleCheckbox}
          />
          <CheckboxGroup
            options={banner_data.handle_zones}
            title="Visible en zonas:"
            type="zones"
            hadnleCheck={handleCheckbox}
          />
          <div className="row justify-content-between">
            <div className="col-md-3">
              {" "}
              <button
                className="btn btn-block btn-outline-dark "
                onClick={disSelect}
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-6">
              {" "}
              <button
                className="btn btn-block btn-success"
                onClick={saveBanner}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      {banner.id !== "new" && (
        <button
          className="btn btn-block btn-link text-danger"
          onClick={toggleModal}
        >
          Eliminar
        </button>
      )}
      <ModalDefault
        show={modal ? true : false}
        toggle={toggleModal}
        actionFun={removeBanner}
        title={banner.name ? `¿Borrar Banner ${banner.name}?` : ""}
        action_btn="danger"
        action_txt="Borrar Banner"
      >
        <p>Esta acción no se puede revertir</p>
      </ModalDefault>
    </div>
  );
};

export default BannerData;
