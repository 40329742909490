import React from "react";

export const Empty = ({
  title,
  desc,
  action,
  action_txt,
  icon,
  classes,
  color,
  dismissible,
  fixed,
  animated,
}) => {
  const p = desc && desc.split("/n");
  const statusColor = color ? `alert-${color}` : "alert-secondary";
  return (
    <div
      className={`alert has-icon ${statusColor} ${classes} ${
        dismissible && "alert-dismissible"
      } ${fixed && "alert-fixed alert-right"} ${
        animated && "alert-animated active"
      }`}
    >
      {icon ? (
        <div className="alert-icon">
          <i className={icon}></i>
        </div>
      ) : null}
      <h4 className="alert-heading"> {title}</h4>
      {p &&
        p.map((text, key) => (
          <p className="mb-0" key={key}>
            {text}
          </p>
        ))}
      {action ? (
        <a
          href={action}
          target="_blank"
          rel="noopener noreferrer"
          className="alert-link"
        >
          {action_txt}
        </a>
      ) : null}
    </div>
  );
};

export const Toast = ({ type = "success", txt, show, toggle }) => {
  return (
    <div
      className={`alert alert-${type} alert-dismissible fade toast ${
        show ? "show" : ""
      }`}
    >
      <button type="button" className="close" onClick={toggle}>
        ×
      </button>{" "}
      {txt}
    </div>
  );
};

export const Alert = ({ children, button, icon, color }) => (
  <div className={`alert alert-${color} ${icon && "has-icon"}`} role="alert">
    {icon && (
      <div className="alert-icon">
        <span className={icon}></span>
      </div>
    )}
    <div className="d-flex justify-content-between">
      {children}
      {button && (
        <button
          className={`btn ${button.class || "btn-primary"}`}
          onClick={button.click}
        >
          {button.txt}
        </button>
      )}
    </div>
  </div>
);
