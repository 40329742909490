import React, { useEffect, useState } from "react";

import { db } from "../../../middleware/firebase";
import Header from "../components/header";
import { Toast } from "../components/alerts";
import { ListItem } from "../../common/lists";

import ProductSection from "./products";
import { useParams } from "react-router-dom";

const BestSellersPage = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const { pagefilter } = useParams();

  useEffect(() => {
    fetchProducts();
    setSelectedProduct(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagefilter]);

  const fetchProducts = async () => {
    const productsRef = await db.collection("best_sellers").get();
    const set_products = [];
    if (productsRef.empty) {
      console.log("No matching documents.");
      return;
    }
    productsRef.forEach((doc) => {
      const product_data = doc.data();
      product_data.id = doc.id;
      if (pagefilter !== product_data.m_page) {
        return;
      }
      set_products.push(product_data);
    });
    setProducts(set_products.sort((a, b) => (a.order > b.order ? 1 : -1)));
  };

  const handleSelect = (ev) => {
    const product_id = ev.currentTarget.id;
    const selected = products.find((i) => i.id === product_id);
    const selected_copy = { ...selected };
    setSelectedProduct(selected_copy);
  };

  const disSelect = () => {
    setSelectedProduct(false);
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar has-sidebar-fluid has-sidebar-expand-xl">
          <div className="page-inner  position-relative px-2 px-md-3">
            <Header title="Más vendidos" />
            <div className="board p-0 ">
              <div
                className="list-group list-group-flush list-group-divider border-top hovered"
                data-toggle="radiolist"
              >
                {products &&
                  products.map((item) => {
                    const date = item.timestamp.toDate();
                    return (
                      <ListItem
                        title={item.descp1}
                        handleSelect={handleSelect}
                        key={`product_${item.id}`}
                        id={item.id}
                        help={
                          "Creado: " +
                          date.toLocaleDateString("es-gb", {
                            month: "long",
                            day: "numeric",
                          })
                        }
                        letter={item.order}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          {selectedProduct && (
            <ProductSection
              productData={selectedProduct}
              disSelect={disSelect}
              fetchProducts={fetchProducts}
            />
          )}
        </div>
      </div>
      <Toast />
    </main>
  );
};

export default BestSellersPage;
