import React, { useEffect, useState } from "react";

import { db } from "../../../middleware/firebase";
import Header from "../components/header";
import { Toast } from "../components/alerts";
import { ListItem } from "../../common/lists";

import MesSection from "./mes";

const MesesPage = () => {
  const [meses, setMeses] = useState([]);
  const [selectedMes, setSelectedMes] = useState(false);

  useEffect(() => {
    fetchMeses();
  }, []);

  const fetchMeses = async () => {
    const mesesRef = db.collection("meses");
    const set_meses = [];
    const snapshot = await mesesRef.orderBy("meses").get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      return;
    }
    snapshot.forEach((doc) => {
      const mes_data = doc.data();
      mes_data.id = doc.id;
      set_meses.push(mes_data);
    });
    setMeses(set_meses);
  };

  const handleSelect = (ev) => {
    const mes_id = ev.currentTarget.id;
    if (mes_id === "new") {
      const new_mes = {
        id: "new",
        timestamp: Date.now(),
      };
      setSelectedMes(new_mes);
    } else {
      const selected = meses.find((i) => i.id === mes_id);
      const selected_copy = { ...selected };
      setSelectedMes(selected_copy);
    }
  };

  const disSelect = () => {
    setSelectedMes(false);
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar has-sidebar-fluid has-sidebar-expand-xl">
          <div className="page-inner  position-relative px-2 px-md-3">
            <Header title="Meses" />

            <div className="board p-0 ">
              <div
                className="list-group list-group-flush list-group-divider border-top hovered"
                data-toggle="radiolist"
              >
                {meses &&
                  meses.map(({ timestamp, meses, comission, id }) => {
                    console.log("index.js:72 | item", {
                      timestamp,
                      meses,
                      comission,
                      id,
                    });
                    const date = new Date(timestamp);
                    return (
                      <ListItem
                        title={`Meses: ${meses}, Comisión: ${comission * 100}%`}
                        help={
                          "Creado: " +
                          date.toLocaleDateString("es-gb", {
                            month: "long",
                            day: "numeric",
                          })
                        }
                        handleSelect={handleSelect}
                        key={`mes_${id}`}
                        id={id}
                        letter={"M"}
                      />
                    );
                  })}
              </div>
              <button
                type="button"
                className="btn btn-primary btn-floated position-fixed"
                title="Add new mes"
                onClick={handleSelect}
                id="new"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>

          {selectedMes && (
            <MesSection
              mes_data={selectedMes}
              disSelect={disSelect}
              fetchMeses={fetchMeses}
            />
          )}
        </div>
      </div>
      <Toast />
    </main>
  );
};

export default MesesPage;
