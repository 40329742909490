import React from "react";
const remove_address = ["distance", "id", "prev"];

const ShippingData = ({ address }) => (
  <div class="card card-fluid">
    <h6 class="card-header"> Dirección de Envío </h6>
    <div class="card-body">
      {Object.keys(address)
        .filter((key) => !remove_address.find((i) => key === i))
        .map((key) => {
          const item = address[key];
          return (
            <AddressRow item={item} title={key} key={`address_Row+${key}`} />
          );
        })}
    </div>
  </div>
);

const AddressRow = ({ item, title }) => (
  <div class="form-row">
    <label for="input01" class="col-md-3 text-capitalize">
      {title}
    </label>

    <div class="col-md-9 mb-3">
      <input
        type="text"
        class="form-control has-readonly"
        readonly
        value={item}
        required=""
      />
    </div>
  </div>
);

export default ShippingData;
