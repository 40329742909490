import React from "react";

const Timeline = ({ items, empty_txt }) => {
  if (!items || items.length === 0) {
    return <p className="text-center text-muted">{empty_txt}</p>;
  }
  return (
    <ul className="timeline">
      {items.map((item, key) => (
        <Item data={item} key={`timeline_${key}`} />
      ))}
    </ul>
  );
};

const Item = ({ data }) => (
  <li className="timeline-item">
    <div className="timeline-figure">
      <span className="tile tile-circle tile-sm">
        {data.icon && <i className={`${data.icon} fa-lg`}></i>}
      </span>
    </div>

    <div className="timeline-body">
      <div className="media">
        <div className="media-body">
          <h6 className="timeline-heading text-box">{data.title}</h6>
          <p className="mb-0">{data.sub_title}</p>
          <p className="timeline-date d-sm-none"> {data.date} </p>
        </div>

        <div className="d-none d-sm-block">
          <span className="timeline-date">{data.date}</span>
        </div>
      </div>
    </div>
  </li>
);

export default Timeline;
