import React from "react";
import Header from "../components/header";

const ProductsHistory = () => {
  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar ">
          <div className="page-inner px-2 px-md-3">
            <Header title="Historial de Actualizaciones de Productos" />
            <div className="page-section"></div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProductsHistory;
