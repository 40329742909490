import React, { useEffect, useState } from "react";

import { db } from "../../../middleware/firebase";
import Header from "../components/header";
import { Toast } from "../components/alerts";
import { ListItem } from "../../common/lists";

import PromotionSection from "./promotion";
import { useParams } from "react-router-dom";

const PromotionsPage = () => {
  const [promotions, setPromotions] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useState(false);
  const { pagefilter } = useParams();

  useEffect(() => {
    fetchPromotions();
    setSelectedPromotion(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagefilter]);

  const fetchPromotions = async () => {
    const promotionsRef = await db.collection("sale_products").get();
    const set_promotions = [];
    if (promotionsRef.empty) {
      console.log("No matching documents.");
      return;
    }
    promotionsRef.forEach((doc) => {
      const promotion_data = doc.data();
      promotion_data.id = doc.id;
      if (pagefilter !== promotion_data.m_page) {
        return;
      }
      set_promotions.push(promotion_data);
    });
    setPromotions(
      set_promotions.sort((a, b) => (a.m_order > b.m_order ? 1 : -1))
    );
  };

  const handleSelect = (ev) => {
    const promotion_id = ev.currentTarget.id;
    const selected = promotions.find((i) => i.id === promotion_id);
    const selected_copy = { ...selected };
    setSelectedPromotion(selected_copy);
  };

  const disSelect = () => {
    setSelectedPromotion(false);
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar has-sidebar-fluid has-sidebar-expand-xl">
          <div className="page-inner  position-relative px-2 px-md-3">
            <Header title="Promociones Home" />
            <div className="board p-0 ">
              <div
                className="list-group list-group-flush list-group-divider border-top hovered"
                data-toggle="radiolist"
              >
                {promotions &&
                  promotions.map((item) => {
                    const date = item.m_timestamp.toDate();
                    return (
                      <ListItem
                        title={item.descp1}
                        handleSelect={handleSelect}
                        key={`promotion_${item.id}`}
                        id={item.id}
                        help={
                          "Creado:" +
                          date.toLocaleDateString("es-gb", {
                            month: "long",
                            day: "numeric",
                          })
                        }
                        letter={item.m_order}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          {selectedPromotion && (
            <PromotionSection
              promotion_data={selectedPromotion}
              disSelect={disSelect}
              fetchPromotions={fetchPromotions}
            />
          )}
        </div>
      </div>
      <Toast />
    </main>
  );
};

export default PromotionsPage;
