import React, { useEffect, useState } from "react";

import { db } from "../../../middleware/firebase";
import Header from "../components/header";
import BannerList from "./list";

const BannersPage = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    const bannersRef = db.collection("featured_cats");
    const set_banners = [];
    const snapshot = await bannersRef.get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      return;
    }

    snapshot.forEach((doc) => {
      const banner = doc.data();
      banner.id = doc.id;
      set_banners.push(banner);
    });
    setBanners(set_banners.sort((a, b) => (a.position > b.position ? 1 : -1)));
  };
  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page ">
          <div className="page-inner px-2 px-md-3">
            <Header title="Categorías Destacadas" />
            <BannerList banners={banners} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default BannersPage;
