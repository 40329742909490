import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Header from "../components/header";
import { db } from "../../../middleware/firebase";
import axios from "../../../middleware/axios";
import { Toast } from "../components/alerts";

import OrdersList from "./orders_list";

const search_options = [
  { value: "order_id", txt: "Por Id de Pedido " },
  { value: "user_email", txt: "Email de Cliente " },
  { value: "user_name", txt: "Nombre de Cliente " },
  { value: "daterange", txt: "Fechas ", type: "daterange" },
];

const OrdersPage = ({ history }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [find_data, setFind_data] = useState({ select_search: "order_id" });
  const [searched, setSearched] = useState(false);
  const [toast, setToast] = useState({});
  const { pagination = 1 } = useParams();
  const limit = 50;
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(false);
      }, 5000);
    }
  }, [toast]);

  const fetchOrders = async () => {
    const ordersRef = db.collection("order_list");
    setLoading(true);
    const set_orders = [];
    let snapshot = ordersRef.orderBy("timestamp", "desc");
    if (pagination > 1) {
      const first = snapshot.limit(limit * (pagination - 1));
      const snapshot_doc = await first.get();
      // Get the last document
      const last = snapshot_doc.docs[snapshot_doc.docs.length - 1];
      snapshot = snapshot.startAfter(last.data().timestamp);
    }
    const results = await snapshot.limit(limit).get();
    setTimeout(() => {
      setLoading(false);
    }, 500);
    if (results.empty) {
      console.log("No matching documents.");
      return;
    }
    results.forEach((doc) => {
      const item = doc.data();
      if (
        item.app_id === "TESTORDER" &&
        user.id !== "kpi5KCqXafa471LEUxL3AKnIi6A3"
      ) {
        return;
      }
      set_orders.push(item);
    });
    setSearched(false);
    setOrders(set_orders);
  };

  const toToOrder = (ev) => {
    const { order_id, user_id } = ev.currentTarget.dataset;
    history.push(`/order/${user_id}/${order_id}`);
  };

  const onKeyDownHandler = (ev) => {
    console.log("index.js:71 | ev", ev.keyCode);
    if (ev.keyCode === 13) {
      findOrder();
    }
  };

  const handleFind = (ev) => {
    const new_find_data = { ...find_data };
    const type = ev.target.id;
    const value = ev.target.value;
    new_find_data[type] = value;
    setFind_data(new_find_data);
  };

  const findOrder = async () => {
    if (find_data.search === "") {
      if (searched) {
        setLoading(true);
        fetchOrders();
        setSearched(false);
      }
      return;
    }
    setLoading(true);
    try {
      const { data } = await axios.post("scriptsOrdersFind", {
        type: find_data.select_search,
        string: find_data.search,
      });
      setTimeout(() => {
        setLoading(false);
      }, 500);
      if (data.error) {
        setToast({
          txt: data.error,
          type: "danger",
        });
        setSearched(false);
        return;
      }
      console.log("index.js:81 | data", data);
      setOrders(data);
      setSearched(true);
    } catch (error) {
      setToast({
        txt: "Algo salió mal",
        type: "danger",
      });
      setSearched(false);
      setLoading(false);
      console.log("index.js:59 | error ", error);
    }
  };

  const button = {
    txt: "Buscar",
    click: findOrder,
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page ">
          <div className="page-inner px-2 px-md-3">
            <Header title="Pedidos Online" />
            <OrdersList
              orders={orders}
              toToOrder={toToOrder}
              update={fetchOrders}
              loading={loading}
              search_options={search_options}
              button={button}
              find_data={find_data}
              pagination={parseInt(pagination)}
              handleFind={handleFind}
              onKeyDownHandler={onKeyDownHandler}
              searched={searched}
            />
          </div>
        </div>
      </div>
      <Toast
        show={toast.txt ? true : false}
        txt={toast.txt}
        type={toast.type || "success"}
        toggle={() => setToast(false)}
      />
    </main>
  );
};

export default OrdersPage;
