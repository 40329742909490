import React, { useEffect, useState } from "react";

import { db } from "../../../middleware/firebase";
import Header from "../components/header";
import { Toast } from "../components/alerts";
import { ListItem } from "../../common/lists";

import BannerSection from "./banner";

const BannersPage = () => {
  const [banners, setBanners] = useState([]);
  const [zones, setZones] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(false);

  useEffect(() => {
    fetchZones();
    fetchBanners();
    setSelectedBanner(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchZones = async () => {
    const zonesRef = await db.collection("warehouse").get();
    const set_zones = [];
    if (zonesRef.empty) {
      console.log("No matching zones.");
      return;
    }
    zonesRef.forEach((doc) => {
      const zone_data = doc.data();
      set_zones.push(zone_data);
    });
    set_zones.push({
      id: "0",
      descrp: "Nacional",
      idzalm: "Nacional",
      xiams: [],
    });
    setZones(set_zones);
  };
  const fetchBanners = async () => {
    const set_banners = [];
    const snapshot = await db
      .collection("banners")
      .where("is_mini_banner", "==", true)
      .get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      setBanners([]);
      return;
    }

    snapshot.forEach((doc) => {
      const banner_data = doc.data();
      banner_data.id = doc.id;
      set_banners.push(banner_data);
    });
    setBanners(set_banners.sort((a, b) => (a.order > b.order ? 1 : -1)));
  };

  const handleSelect = (ev) => {
    const banner_id = ev.currentTarget.id;
    if (banner_id === "new") {
      const new_banner = {
        id: "new",
      };
      setSelectedBanner(new_banner);
    } else {
      const selected = banners.find((i) => i.id === banner_id);
      const selected_copy = { ...selected };
      setSelectedBanner(selected_copy);
    }
  };

  const disSelect = () => {
    setSelectedBanner(false);
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar has-sidebar-fluid has-sidebar-expand-xl">
          <div className="page-inner  position-relative px-2 px-md-3">
            <Header title="Mini Banners" />
            <p>Banners que apareceran en la lista de productos</p>
            <div className="board p-0 ">
              <div
                className="list-group list-group-flush list-group-divider border-top hovered"
                data-toggle="radiolist"
              >
                {banners &&
                  banners.map((item) => {
                    const date = item.timestamp.toDate();
                    return (
                      <ListItem
                        title={item.category.replace(/-/g, " ")} //remove dashed from text
                        handleSelect={handleSelect}
                        key={`banner_${item.id}`}
                        id={item.id}
                        help={
                          "Modificado: " +
                          date.toLocaleDateString("es-gb", {
                            month: "long",
                            day: "numeric",
                          })
                        }
                        letter={item.order}
                      />
                    );
                  })}
              </div>
              <button
                type="button"
                className="btn btn-primary btn-floated position-fixed"
                title="Add new banner"
                onClick={handleSelect}
                id="new"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>
          {selectedBanner && (
            <BannerSection
              banner_data={selectedBanner}
              zones={zones}
              disSelect={disSelect}
              fetchBanners={fetchBanners}
            />
          )}
        </div>
      </div>
      <Toast />
    </main>
  );
};

export default BannersPage;
