const admin_permissions = {
  admin: 1,
  editor: 2,
  read: 4,
  admin_sede: 8,
};

export function has_access(privilige) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.priv) {
    return false;
  }
  const user_priv = parseInt(user.priv);
  console.log("page_permissions.js:13 | user_priv", user_priv);
  if (user_priv & admin_permissions.admin || user_priv & privilige) {
    return true;
  } else {
    return false;
  }
}

export function check_access(pirv_value, privilige) {
  if (pirv_value & admin_permissions[privilige]) {
    return true;
  } else {
    return false;
  }
}
